export default function Header() {
  return (
    <header className="sticky top-0 z-50 w-full bg-white shadow-md">
      <nav className="flex items-center justify-center py-4 bg-white shadow-sm">
        <a href="/">
          <div className="flex items-center">
            <img
              src="./logo.png"
              alt="Yum Coffee Logo"
              className="h-12 w-auto"
            />
            <span className="ml-2 text-[1.5em] font-extrabold text-[#5062AA]">
              Yum Coffee
            </span>
          </div>
        </a>
      </nav>
    </header>
  );
}
