import { LayoutProps } from "../types";
export default function Body({ children }: LayoutProps) {
  return (
    <main className="w-full min-h-screen relative">
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed"
        style={{
          backgroundImage: "url('/background.webp')",
        }}
      />
      <div className="absolute inset-0 bg-black/60" />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl w-full min-h-screen bg-cover bg-center bg-fixed relative z-10">
        {children}
      </div>
    </main>
  );
}
