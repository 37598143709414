type ContactCardProps = {
  label: string;
  link: string;
};

export default function ContactCard({ label, link }: ContactCardProps) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block border-2 border-[#6478C9] p-6 rounded-xl bg-[#4F61A9] hover:bg-[#445294] hover:border-[#8499E3] text-white transition-all duration-200 cursor-pointer shadow-md"
    >
      <div className="text-center">
        <h1 className="text-xl font-bold">{label}</h1>
      </div>
    </a>
  );
}
