import ContactCard from "./components/ContactCard";

type Contact = {
  id: number;
  label: string;
  link: string;
};

const contacts: Contact[] = [
  {
    id: 0,
    label: "Whatsapp",
    link: "https://wa.me/60122482366",
  },
  {
    id: 1,
    label: "Instagram",
    link: "https://www.instagram.com/yumcoffeenow",
  },
  {
    id: 2,
    label: "Facebook",
    link: "https://www.facebook.com/share/15BP57Figy",
  },
  {
    id: 3,
    label: "小红书 (XHS)",
    link: "https://www.xiaohongshu.com/user/profile/62753cef000000002102be7b",
  },
];

export default function Home() {
  function renderContacts() {
    return contacts.map((contact) => {
      return (
        <ContactCard
          key={contact.id}
          label={contact.label}
          link={contact.link}
        />
      );
    });
  }

  return (
    <div className="py-5 space-y-5">
      <div className="space-y-5">{renderContacts()}</div>
    </div>
  );
}
