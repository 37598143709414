import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

import { LayoutProps } from "./types";

export default function Layout({ children }: LayoutProps) {
  return (
    <div>
      <Header />
      <Body>{children}</Body>
      <Footer />
    </div>
  );
}
