export default function Footer() {
  return (
    <footer className="z-[99] fixed bottom-0 left-0 w-full bg-gray-800 text-white py-4">
      <div className="container mx-auto px-4">
        <p className="text-center text-sm">
          © {new Date().getFullYear()} Yum Coffee. All rights reserved.
        </p>
        <div className="flex items-center justify-center">
          <a
            href="/privacy-policy"
            className="text-sm text-gray-400 hover:text-white inline-block transition-colors duration-200"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
