const PrivacyPolicy = () => {
  return (
    <div className="p-5 pb-10 bg-white">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          1. Information We Collect
        </h2>
        <p className="mb-4">
          When you visit Yum Coffee, we may collect certain information about
          your device, browsing actions, and patterns. This includes:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Device information</li>
          <li>Location information</li>
          <li>Information you provide when placing orders</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">We use the information we collect to:</p>
        <ul className="list-disc ml-6 mb-4">
          <li>Process your orders and payments</li>
          <li>Improve our website and services</li>
          <li>Send you marketing communications (with your consent)</li>
          <li>Analyze website usage and trends</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Information Sharing</h2>
        <p className="mb-4">
          We do not sell or trade your personal information. We may share your
          information with:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Service providers who assist our operations</li>
          <li>Law enforcement when required by law</li>
          <li>Business partners with your consent</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Cookies</h2>
        <p className="mb-4">
          We use cookies to enhance your browsing experience. You can control
          cookies through your browser settings.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc ml-6 mb-4">
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Opt-out of marketing communications</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
        <p className="mb-4">
          If you have questions about this Privacy Policy, please contact us at:
          <br />
          hello@yumcoffee.com
        </p>
      </section>

      <footer className="text-sm text-gray-600">
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
